.App .modal.custom-modal {
  width: 300px;
  right: 0px !important;
  left: auto;
  --bs-modal-bg: var(--dark) !important;
  transition: opacity 2000ms ease-in-out;
}
.fixed-top {
  z-index: 999 !important;
}
.custom-modal .modal-content {
  width: 300px;
}
.custom-modal .list-group {
  --bs-list-group-bg: var(--dark) !important;
}
.list-group-item {
  cursor: pointer;
  border: none;
}
.list-group-item:hover {
  background-color: rgba(59, 57, 57, 0.164) !important;
}

.logo-size {
  width: 100px;
}
.accordion-item .accordion-button:hover {
  color: var(--main-primary) !important;
  background-color: rgba(59, 57, 57, 0.164) !important;
}
.humber-menu {
  background-color: #000;
  border-radius: 50px;
  padding: 10px;
  padding: 10px;
  border: 2px solid #ffcd00;
}
.theme-button {
  background: var(--main-primary);
  border-radius: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
}
.list-group-item:hover,
.list-group-item.active {
  color: var(--main-primary) !important;
  background-color: initial;
}
.custom_accordion .accordion-button {
  background-color: transparent;
  color: #fff;
  box-shadow: unset;
  padding: 8px 16px;
}
.accordion-button::after {
  filter: invert(1);
}
.accordion-body {
  background: #2e2e2e;
}

.close_btn {
  height: 2em !important;
  background: transparent;
  border: none;
}
