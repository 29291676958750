.pq-breadcrumb {
    /* background-image: url(); */
    background: #080b0e;
    color: #ffffff;
    padding: 130px 0;
    position: relative;
    text-align: left;
    background-size: cover !important;
    background-position: top center !important;
    background-repeat: no-repeat !important;
}
.pq-breadcrumb .pq-breadcrumb-title, .pq-breadcrumb .pq-breadcrumb-container {
    position: relative;
    z-index: 9;
}
.pq-breadcrumb .pq-breadcrumb-container {
    position: relative;
    z-index: 9;
}
.pq-breadcrumb-container .breadcrumb {
    background: transparent;
    padding: 0;
    margin: 0;
}
.pq-breadcrumb-container .breadcrumb li {
    list-style-type: none;
    margin-right: 15px;
    padding-left: 0;
}
.pq-breadcrumb-container .breadcrumb li a {
    color: #fff;
}
.pq-breadcrumb-title h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 80px;
    color: #FFFFFF;
}
.pq-breadcrumb-title p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
}
.service-content h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 32px;
    color: #000000;
}

.service-content p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #646464;
}
.service_card_img{
    width: 90%;
    border-radius: 10px;
}
ul.menu {
    padding-left: 0;
    margin: 0;
}
ul.menu li {
    width: 48%;
    float: left;
    list-style-type: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #646464;

}
ul.menu li a {
    display: inline-block;
    width: 100%;
}
.service-card {
    background: rgba(255, 255, 255, 0.002);
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.service-card  h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    color: #000000;
}
.service-card p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #646464;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
@media only screen and (max-width: 800px) {
    ul.menu li {
        width: 100%;
        float: left;
        list-style-type: none;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #646464;
    
    }
    .image-part img {
        width: 100%;
    }
  }

