.footer-color {
    background: linear-gradient(2.61deg, #000000 0.32%, rgba(0, 0, 0, 0.53) 204.2%);
}
.footer-section p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #C1C1C1;
}
.menu-links h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
    color: #FFFFFF;
}
.menus-name li a, .copy-right p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #C1C1C1;
}
.menus-name li a:hover {
    color: var(--main-primary);
}
.twitter_icon{
    width: 31px;
}