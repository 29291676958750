@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap');


body {
  font-family: 'Roboto', sans-serif;
}

:root {
  --main-primary: #FFCD00;
  --dark: #000;
  --light: #fff
}
.common-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
}
.light-color {
  color: var(--light) !important;
}
.dark-color {
  color: var(--dark);
}
.theme-color {
  color: var(--main-primary
  );
}
section{
  margin: 100px 0px;
}

#bottone1:hover , 
#bottone2:hover,
#bottone3:hover
 {
  box-shadow: 7px 5px 56px -14px #FFCD00;
 }
 
 #bottone1:active,
 #bottone2:active,
 #bottone3:active

  {
  transform: scale(0.97);
  box-shadow: 7px 5px 56px -10px #FFCD00;
 }
 #bottone1 ,
 #bottone2,
 #bottone3{
  padding-left: 33px;
  padding-right: 33px;
  padding-bottom: 16px;
  padding-top: 13px;
  border-radius: 50px;
  background: var(--main-primary);
  border: none;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  transition: 0.4s;
  color: var(--dark);
  text-decoration: none;
 }
 .light-bacground {
  background-color: var(--light);
 }
 .gRNicc.active {
  background: transparent;
  color: #000;
}
.vertical-item-row section {
  margin: 20px 0px;
}