.fs-24 {
  font-size: 24px;
}

.fs-40 {
  font-size: 40px;
}

.career-banner-title {
  color: var(--dark);
  font-size: 40px;
}

.career-banner-para {
  color: #646464;
}

.available-job {
  background-color: var(--main-primary);
  text-decoration: none;
  color: var(--dark);
  border-radius: 30px;
  padding: 10px 20px;
  font-weight: bold;
  margin: 30px auto;
  display: block;
  width: fit-content;
}

.available-job:hover {
  color: #fff;
}

.benifit-section {
  width: 86px;
  height: 86px;
  background: linear-gradient(270deg, #ffcd00 99.47%, #fffcfc 99.48%);
  box-shadow: 0px 9px 18px rgba(250, 55, 25, 0.3);
  border-radius: 86px 0px 86px 86px;
}

.benifit-title {
  font-size: 40px;
  color: var(--dark);
}

.w-30 {
  width: 40%;
}

.job-title {
  font-size: 36px;
  color: var(--dark);
}

.job-para {
  color: #646464;
}

.project-title {
  color: var(--dark);
  font-size: 24px;
}

.project-position {
  font-size: 20px;
  color: var(--dark);
}

.learn-more {
  background-color: var(--dark);
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 16px;
  border-radius: 10px !important;
}

.learn-more:hover {
  background-color: var(--main-primary);
  color: var(--dark) !important;
}

.job-contents {
  background: #ffffff;
  box-shadow: 0px 0px 10px #ffcd00;
  border-radius: 6px;
}

.qualities-section {
  background-image: url(../../assests/images/quality-image.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 650px;
  background-size: 100% 100%;
}

.qualities-image {
  background: #ffffff;
  box-shadow: 0px 44px 66px rgba(0, 4, 71, 0.21);
  border-radius: 487px;
  width: 487px;
  height: 487px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  position: relative;
  top: 40px;
}

.bee-quality-one {
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.bee-quality-one li {
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  flex-direction: row-reverse;
}

.bee-quality-one li:nth-child(1) {
  margin-left: -120px;
}

.bee-quality-one li:nth-child(2) {
  margin-left: -140px;
}

.bee-quality-one li:nth-child(3) {
  margin-left: -140px;
}

.bee-quality-one li:nth-child(4) {
  margin-left: -90px;
}

.bee-quality-two {
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

.bee-quality-two li {
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.bee-quality-two li:nth-child(1) {
  margin-right: -150px;
}

.bee-quality-two li:nth-child(2) {
  margin-right: -120px;
}

.bee-quality-two li:nth-child(3) {
  margin-right: -100px;
}

.bee-quality-two li:nth-child(4) {
  margin-right: -100px;
}

.qualities-para {
  color: #646464;
}

.numbers {
  color: #fff;
  background: #ffcd00;
  box-shadow: 0px 12px 9px rgba(0, 4, 71, 0.21);
  border-radius: 51px;
  width: 51px;
  height: 51px;
}

.job-contents p {
  width: 33.33%;
}

@media only screen and (max-width: 760px) {
  .job-contents {
    flex-wrap: wrap;
    gap: 10px;
  }

  .qualities-image {
    width: 310px;
    height: 310px;
  }
}

@media only screen and (max-width: 600px) {
  .numbers {
    transform: rotate(-90deg);
  }

  .qualities-image {
    width: 280px;
    height: 280px;
    top: 150px;
    transform: rotate(90deg);
  }

  .image-career-bee {
    transform: rotate(270deg);
  }

  .bee-quality-two {
    flex-direction: column-reverse;
  }

  .bee-quality-one {
    flex-direction: column-reverse;
  }
  .image-career-v {
    transform: rotate(-90deg);
  }
}

@media only screen and (max-width: 500px) {
  .career-banner-title {
    font-size: 28px;
  }

  .benifit-title {
    font-size: 28px;
  }

  .job-title {
    font-size: 26px;
  }

  .project-title {
    font-size: 18px;
  }

  .project-position {
    font-size: 16px;
    font-weight: 700;
  }

  .fs-40 {
    font-size: 28px;
  }

  .fs-24 {
    font-size: 18px;
  }

  .job-contents {
    flex-direction: column;
    justify-content: center !important;
  }

  .learn-more {
    font-size: 12px;
  }

  .image-career-v {
    transform: rotate(-90deg);
  }
  .job-contents p {
    width: 33.33%;
  }
}

/* career view  */
.carrer_header {
}

.carrer_header h3 {
  font-weight: 700;
  text-transform: capitalize;
  color: #000000;
}

.carrer_header p,
.carrer_header ul li {
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #797979;
}

.list_disc {
  list-style-type: disc;
}

.apply_btn,
.apply_btn:hover {
  background: #ffcd00;
  border-radius: 50px;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 22px;
  text-decoration: none;
}

.carrer_header-icon1 {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: -1;
}

.carrer_header-icon1 img {
  width: 100px;
}

.carrer_header-icon2 {
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: -1;
}

.carrer_header-icon2 img {
  width: 80px;
}

.career_back {
  /* position: absolute;
    top: 0;
    left: 20px; */
  padding-bottom: 20px;
}

.career_back a {
  font-size: 16px;
  line-height: 14px;
  color: #000;
  text-decoration: none;
}

.career_back span {
  border: 2px solid #f3b600;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  display: inline-block;
  color: #000;
  font-size: 12px;
}

.career_category-col .career_category-sm {
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  text-transform: capitalize;
  color: #797979;
}

.career_category-col .career_category-md {
  font-weight: 500;
  font-size: 30px;
  line-height: 160%;
  text-transform: capitalize;
  color: #263238;
}

.career_category {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}

.career_category-col:not(:last-child) {
  border-right: 1px solid #dbdbdb;
  /* padding-right: 40px */
}

.career_category-list {
  /* gap: 50px; */
}

.career_category-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.border-t-0 {
  border-top: 0px;
}

.career_form .form-control {
  background: #ffffff;
  border: 1px solid rgba(243, 182, 0, 0.2);
  border-radius: 6px;
  box-shadow: unset;
  padding: 15px 14px;
  font-size: 18px;
}

.file_upload-placeholder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #24242400;
  padding: 15px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.file_upload {
}

.file_upload-inner {
  opacity: 0;
  cursor: pointer;
}

.form_error {
  font-size: 12px;
  color: red;
}
.file_upload-placeholder_one {
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .career_category-list {
    flex-wrap: wrap;
  }

  .career_category-col {
    width: 50%;
    border: none !important;
    margin-bottom: 20px;
  }

  .carrer_header-icon1,
  .carrer_header-icon2 {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .career_category-col {
    width: 100%;
    margin-bottom: 40px;
  }

  .job-contents p {
    width: auto;
  }
}
