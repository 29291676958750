.newsroom_card-img img {
  border-radius: 20px;
}

.newsroom_card-row {
  background: #ffdb4a;
  padding: 20px;
  border-radius: 20px;
}

.newsroom_card-content h3 {
  font-weight: 600;
  font-size: 36px;
  line-height: 53px;
  color: #000000;
}

.newsroom_card-content p {
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
}

.newsroom_card-content .newsroom_card-date {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.newsroom_list-content {
}

.newsroom_list-content h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 60px;
}

.newsroom_list-content .newsroom_list-date {
  color: #8e8e93;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.newsroom_list-content p {
  color: #666666;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: 74px;
}

.newsroom_list-content a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-decoration: none;
}
.newsroom_list-pagination ul {
  justify-content: center;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #ffcd00 !important;
  color: #ffffff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
}
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root {
  background: #ffffff;
  border: 1px solid rgba(255, 205, 0, 0.75) !important;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  color: #333333;
  font-weight: 600;
  font-size: 16px;
}
.MuiPaginationItem-previousNext {
  border: none !important;
}

.newsroom_list-img img {
  height: 240px;
  object-fit: cover;
  border-radius: 20px;
}

.newsroom_list-card {
  border: 1px solid #e0e0e7;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.newsroom_list-content a span {
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  background: #ffcd00;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.newsroom_list-pagination ul {
  gap: 10px;
}

.newsroom_list-pagination ul li a {
  text-decoration: none;
  color: #333333;
}

.newsroom_list-pagination ul li:first-child a,
.newsroom_list-pagination ul li:last-child a {
  font-weight: 600;
  font-size: 16px;
}

.newsroom_list-pagination ul li:not(:first-child, :last-child) a {
  background: #ffffff !important;
  border: 1px solid rgba(255, 205, 0, 0.75) !important;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  color: #333333 !important;
  font-weight: 600;
  font-size: 16px;
}

.newsroom_list-pagination ul li a.active {
  background-color: #ffcd00 !important;
  color: #ffffff !important;
}
.css-18lqwt2.Mui-selected {
  background-color: #ffcd00 !important;
  color: #ffffff;
}
.newsroom_list-pagination ul li a.disabled {
  color: #cccccc !important;
}
.css-18lqwt2 {
}

.blog_detail-card::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: url("../../assests/images/blog-bg.png") no-repeat;
  background-size: 100% 100%;
  height: 360px;
  background-position: center;
  border-radius: 30px;
}

.blog_detail-img {
  width: 85%;
  margin: 0 auto;
  position: relative;
  top: 70px;
  background: #f6f6f6;
  padding: 60px;
  border-radius: 30px;
  height: 500px;
}

.blog_detail-img img {
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.blog_detail-content h3 {
  color: #212326;
  font-weight: 500;
  font-size: 30px;
  line-height: 129.7%;
}

.blog_detail-content p,
.blog_detail-content ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #797979;
}

.blog_detail-content ul.disc {
  list-style-type: disc;
}

.blog_detail-content ul.decimal {
  list-style-type: decimal;
}

.quotes_card {
  background-color: #ffcd00;
  padding: 5rem;
  border-radius: 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: 129.7%;
}

.quotes_icon img {
  width: 60px;
}

.blog_detail-head h3 {
  font-weight: 700;
  font-size: 36px;
  line-height: 129.7%;
}

.blog_detail-head p {
  color: #797979;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
}

.badge_txt {
  background: rgba(255, 205, 0, 0.2);
  border-radius: 50px;
  color: #ffcd00;
  font-weight: 500;
  font-size: 18px;
  display: inline-block;
  padding: 2px 14px;
  margin-bottom: 10px;
}

@media screen and (max-width: 480px) {
  .blog_detail-img {
    padding: 35px;
    height: auto;
  }

  .blog_detail-card::before {
    content: "";
    height: 260px;
  }

  .quotes_card {
    padding: 2rem;
  }
}
