.form-modal {}

.form_modal-card .form-control {
    background: #FFFFFF;
    border: 1px solid #FFCD00;
    border-radius: 30px;
    box-shadow: unset;
}

.form_modal-card .form-label {
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    margin: 0;
}

.apply_btn,
.apply_btn:hover {
    background: #FFCD00;
    border-radius: 50px;
    color: #000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 22px;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background-color: #f5f5f5 !important;outline: none;
    position: relative;
    background-color: #f5f5f5;
    border: none !important;
    border-radius: 50px 0 0 50px;
    outline: none !important;
    cursor: context-menu !important;
}

.react-responsive-modal-modal {
    border-radius: 30px;
}

.form_modal-title h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    color: #000000;
}

.form_modal-title p {
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    color: #2B2A35;
}

.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #ffdb49 !important;
    border-radius: 50px;
    line-height: 25px;
    height: 39px;
    width: 100%;
    outline: none;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    border-radius: 50px 0 0 50px;
}