.zoom-in-zoom-out {
 
 margin: 299px;
  animation: zoom-in-zoom-out 2s ease-out infinite;
}
.zoom-in-zoom-out img{
            width: 10%;
            height: 10%;
            margin: 0 auto;
            display: flex;
            
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}