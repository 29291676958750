.about-title h1, .about-title h3  {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 53px;
    color: var(--dark);
}
.about-title p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: var(--dark);
}
.count-card h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 46.512px;
    line-height: 66px;
    color: var(--main-primary);
}
.about_banner{
    width: 75%;
}
.about-card {
    background: #FFFFFF;
    box-shadow: 12px 19px 32px rgba(126, 126, 126, 0.1);
}
.icon-background {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    width: 80px;
    height: 80px;
    text-align: center;
}
.content-section h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: var(--dark);
}
.content-section p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #646464;
}
.placeholder-text {
    color: rgba(0, 0, 0, 0.05);
    font-style: normal;
    font-weight: 600;
    font-size: 200px;
    line-height: 77px;
    top: -100px;
    left: -50px;
    z-index: -1;
}
section.special-space {
    margin: 150px 0px;
}
.timeline-card-content {
    min-height: auto !important;
}
.team-card {
    background: rgba(255, 255, 255, 0.002);
    border: 1px solid rgba(35, 20, 89, 0.09);
    box-shadow: 0px 0px 20px rgba(255, 205, 0, 0.4);
    border-radius: 24px;
}
/*Magic card*/
.card {
    background: rgba(255, 255, 255, 0.002);
    border: 1px solid rgba(35, 20, 89, 0.09);
    box-shadow: 0px 0px 20px rgba(255, 205, 0, 0.4);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
   }
   
   .card-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    padding: 0 1rem;
   }
   
   .card-img {
    --size: 100px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    transform: translateY(-50%);
    position: relative;
    transition: all .3s ease-in-out;
   }
   
   
   
   /*Text*/
   .text-title {
    text-transform: uppercase;
    font-size: 0.75em;
    color: #42caff;
    letter-spacing: 0.05rem;
   }
   
   .text-body {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #666666;
   }
.card-title h4{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
}
.card-title p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    color: #666666;
    text-align: center;
}
.hr {
    border-bottom: 1px solid #ccc;
    width: -webkit-fill-available;
}
ul {
    list-style-type: none;
  }
.social {
    background: rgba(35, 20, 89, 0.09);
    border-radius: 20px;
    padding: 10px 15px;
}
@media only screen and (max-width: 600px) {
    .gRNicc {
        font-size: 17px !important;
        padding-right: 45px !important;
    }
    .juPOpZ{
        font-size: 16px !important;
        padding-right: 45px !important;
    }
    
  }
  .content-section-ul{
    list-style: disc;
  }