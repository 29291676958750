.fs-40 {
    font-size: 40px;
}

.fs-36 {
    font-size: 36px;
}

.contact-para {
    color: #646464;
}

.call-icon {
    background-color: var(--main-primary);
    width: 60px;
    height: 60px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-35 {
    width: 35%;
}

.h-425 {
    height: 425px;
}

.call-banner-number {
    text-decoration: none;
    color: var(--dark);
    font-weight: bold;
}

.call-banner-number:hover {
    color: var(--main-primary);
}

.map-contact-link {
    text-decoration: none;
    color: var(--main-primary);
}

.fs-20 {
    font-size: 20px;
}

.location-card {
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #E0E0E7;
    border-radius: 20px 20px 21px 20px;
}

.location-contact {
    background: linear-gradient(90deg, #FFCD00 0%, #FFCD00 100%);
    border-radius: 0px 0px 20px 20px;
    color: var(--dark);
    text-decoration: none;
}

.location-contact:hover {
    background: var(--dark) !important;
    color: #fff;
}

.inqury-tag {
    background-color: var(--dark);
    color: #fff;
    padding: 5px 10px;
    width: fit-content;
    border-radius: 20px;
}

.contact-form input,
textarea {
    background: #FFFFFF;
    border: 1px solid #E0E0E7;
    border-radius: 30px;
    width: 100%;

}

.contact-form input {
    padding: 10px;
}


.contact-submit-btn {
    background-color: var(--main-primary);
    color: var(--dark);
    border-radius: 30px;
    padding: 10px 20px;
    border: none;
}

.contact-form-bg {
    background: #F5F5F5;
    border-radius: 20px;
    padding: 40px;
}

.btn-ring {
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: ring 1.2s linear infinite;
}


@keyframes ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 500px) {

    .fs-20 {
        font-size: 16px;
    }

    .fs-40 {
        font-size: 28px;
    }

    .fs-36 {
        font-size: 20px;
    }

    .contact-form-bg {

        padding: 10px;
    }


}